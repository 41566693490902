body, html {
    background-color: black;
    color: white;
    font-family: 'Raleway', sans-serif;
}

.services-page {
    width: 100%;
    background-color: #F5FEFD;
}

.services-head__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 100%;


    background-size: cover;
    background-position: center center;
}

.services-head__tint {
    width: 100%;
    padding: 15rem 0 10rem 0;
    background: rgba(0, 0, 0, .55);
}

.services-header {
    width: 95%;
    margin: 0 auto;
    font-size: clamp(2.5rem, 2.2692rem + 0.9846vw, 3.5rem);
    font-weight: 450;
    letter-spacing: 2px;
}

.carousel-wrapper {
    width: 95%;
    margin: 1rem auto 0 auto;
}


.card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-height: auto;
    margin: 1rem;
    border-radius: 5px;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.3);
    color: #414141;
    background: #FAFAFA;

}

.card .service-type {
    margin: .5rem auto 0 auto;
    padding: 0;
    font-size: clamp(1.6rem, 1.5538rem + 0.1969vw, 1.8rem);
    font-weight: 450;
}

.card .card__image {
   max-height: auto;
   border-radius: 5px 5px 0 0;
}

.card .price {
    font-weight: 400;
    font-size: clamp(1.2rem, 1.1538rem + 0.1969vw, 1.4rem);
    margin: 0 auto;
}

.card-line {
    width: 90%;
    margin: .5rem auto;
    border: 1px solid #6AA5EC;
}

.card .description {
    line-height: 1.4rem;
    padding: 0 10px 10px 10px;
    border-radius: 5px;
    font-size: clamp(0.8rem, 0.6rem + 0.32vw, 1rem);
}

/* Lower Section */

.services-bottom__container {
    width: 100%;
    padding: 0 0 1rem 0;
    color: #414141;
}

    .services-bottom__typography {
        font-size: clamp(1rem, 0.9308rem + 0.2954vw, 1.3rem);
        width: 80%;
        margin: 0 auto 1rem auto;
        line-height: 2rem;
        font-weight: 400;
    }

    .services-message__wrapper {
        display: contents;
    }

    .services-message__btn {
        width: 150px;
        background-color: #6AA5EC;
        font-size: clamp(1rem, 0.9538rem + 0.1969vw, 1.2rem);
        font-weight: 400;
        padding: 10px 20px;
        transition: all ease 0.2s;
        color: #FFFFFF;
        border: none;
        border-radius: 5px;
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
    }

    .services-message__btn:hover {
        background: black;
        cursor: pointer;
    }


@media screen and (max-width: 760px) {
    .services-head__tint {
        padding: 10rem 0 5rem 0;
    }
}

@media screen and (max-width: 699px) {
    .services-bottom__typography {
        width: 95%;
        font-size: 1rem;
    }
    
}

@media screen and (max-width: 375px) {
    
    .services__header {
        line-height: 1.8rem;
    }
    .services__header h3 {
        font-size: 1.3rem;
    }
    .services__header p {
        font-weight: 200;
    }
}


