body {
  height: 100%;
}

.App {
  min-height: 100%;
  max-width: 100%;
  width: 1920px;
  margin: 0 auto;
  text-align: center;
}


