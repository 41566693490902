.about__header {
    width: 100%;
    margin: 0 auto;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.about__tint {
    width: 100%;
    padding: 15rem 0 10rem 0;
    background: rgba(0, 0, 0, .55);
}

.about-heading {
    font-size: clamp(2.5rem, 2.2692rem + 0.9846vw, 3.5rem);
    font-weight: 450;
    letter-spacing: 2px;
    height: 100%;
    text-align: center;
}

.mission__container {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem 0;
    background-color: #0081E4;
}

    .mission-header {
        font-weight: 400;
        font-size: clamp(2.5rem, 2.3846rem + 0.4923vw, 3rem);
        margin: 0 auto;
        padding: 20px;
        text-align: right;
        width: 50%;
    }

    .mission__paragraph {
        border-left: 1px solid white;
        font-size: 1rem;
        line-height: 1.6rem;
        padding: 20px;
        text-align: left;
        width: 50%;
    }

.how__container {
    width: 100%;
    align-items: center;
    background-color: #E1D9D1;
    color: black;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    padding: 4rem 0;
}

    .how-header {
        font-weight: 400;
        font-size: clamp(2.5rem, 2.3846rem + 0.4923vw, 3rem);
        margin: 0 auto;
        padding: 20px;
        text-align: left;
        width: 50%;
    }

    .how__paragraph {
        border-right: 1px solid black;
        font-size: 1rem;
        line-height: 1.6rem;
        padding: 20px;
        text-align: left;
        width: 50%;
    }

@media screen and (max-width: 768px) { 

    .mission__container, .how__container {
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    
    .mission-header {
        padding: 0;
        text-align: left;
        width: 95%;
    }

    .mission__paragraph {
        width: 88%;
        font-size: .9rem;
        line-height: 1.4rem;
        padding: 0 0 0 20px;
    }

    .how-header {
        width: 95%;
        text-align: right;
        padding: 0;
    }

    .how__paragraph {
        width: 88%;
        font-size: .9rem;
        line-height: 1.4rem;
        padding: 0 20px 0 0;
    }
          }



