.ceramic-container {
    width: 100%;
    background: #FFFFFF;
    color: #414141;
}

    .ceramic-head {
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .ceramic-head-tint {
        padding: 15rem 0 10rem 0;
        width: 100%;
        background: rgba(0, 0, 0, .55);
    }

        .ceramic-header {
            font-family: 'Raleway', sans-serif;
            width: 95%;
            font-size: clamp(2.5rem, 2.2692rem + 0.9846vw, 3.5rem);
            font-weight: 450;
            letter-spacing: 2px;
            margin: 0 auto;
            line-height: 3.5rem;
            color: #FFFFFF;
        }

    .ceramic-main {
        width: 100%;
        padding: 2rem 0 4rem 0;
    }

        .why-ceramic {
            font-size: clamp(1.8rem, 1.1rem + 1.12vw, 2.5rem);
            font-weight: 400;
            margin: 1rem auto 2rem auto;
        }

        /* ceramic image */
        .ceramic-benefits {
            margin: 0 auto;
        }

            .ceramic-benefits > img {
                max-width: 100%;
            }


        .ceramic-card-wrapper {
            width: 95%;
            height: auto;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            align-content: center;
            justify-content: space-evenly;
        }

            .ceramic-card {
                padding: 1rem 0;
                width: 30%;
                height: 100%;
                box-shadow: 0 4px 30px rgba(0, 0, 0, .2);
                transition: all ease .3s;
            }

            #card1:hover, #card2:hover, #card3:hover {
                transition: all ease .3s;
                box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
                transform: translateY(-5px);
            }

                .ceramic-card-line {
                    width: 95%;
                    margin: 0 auto .5rem auto;
                    border: 1px solid #6AA5EC;
                }
                
                .ceramic-card-header {
                    color: #414141;
                    font-size: 1.4rem;
                    font-weight: 400;
                    width: 90%;
                    margin: 0 auto;
                }

                .ceramic-card-description {
                    margin: 0 auto;
                    padding: 1rem 0;
                    width: 90%;
                    color: #414141;
                    line-height: 1.5rem;
                    font-size: clamp(0.8rem, 0.6rem + 0.32vw, 1rem);
                }


    .ceramic2-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        background: #6AA5EC;
        padding: 2rem 0;
    }

        .ceramic2-wrapper {
            max-width: 45%;
            color: #FFFFFF;
        }

            .ceramic2-header {
                color: #FFFFFF;
              font-size: clamp(1.8rem, 1.1rem + 1.12vw, 2.5rem);
                font-weight: 400;
                margin: 0;
                text-align: left;
            }
            .ceramic2-header2 {
                color: black;
                text-decoration: none;
            }

            /* mobile logo */
            .ceramic2-logo__wrapper2 {
                display: none;
            }

            .ceramic2-subheading {
                line-height: 1.6;
                font-size: clamp(0.8rem, 0.4rem + 0.64vw, 1.2rem);
                text-align: left;
                margin-bottom: 2rem;
            }

            .op-btn {
                text-decoration: none;
                color: #FFFFFF;
                border: 1px solid #FFFFFF;
                padding: 8px 15px;
                border-radius: 3px;
                font-weight: 500;
                margin: 0 auto;
                transition: all ease .3s;
                font-size: clamp(0.8rem, 0.6rem + 0.32vw, 1rem);
            }
            .op-btn:hover {
                color: #6AA5EC;
                background-color: white;
                box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
                transform: translateY(-5px);
                transition: all ease .3s;
            }

        .ceramic2-logo__wrapper {
            max-width: 50%;
        }

            .op-logo {
                width: 100%;
            }


    .ceramic-faqs {
        width: 100%;
        padding: 2rem 0;
    } 

        .ceramic-faqs-header {
            font-size: clamp(1.8rem, 1.1rem + 1.12vw, 2.5rem);
            font-weight: 400;
        }

        .faqs-wrapper {
            width: 75%;
            margin: auto;
        }

        .ceramic-faqs-subheading {
            width: 75%;
            margin: 1rem auto;
            line-height: 1.5rem;
            font-size: clamp(0.8rem, 0.4rem + 0.64vw, 1.2rem);
            font-weight: 450;
        }

        .ceramic-faqs-btn {
            background-color: #6AA5EC;
            border: none;
            border-radius: 5px;
            color: #FFFFFF;
            padding: 12px 20px;
            cursor: pointer;
            transition: all ease .3s;
            text-align: center;
            margin: 0 auto;
            font-size: clamp(0.8rem, 0.6rem + 0.32vw, 1rem);
            box-shadow: 0 4px 30px rgba(0, 0, 0, .2);
        }

        .ceramic-faqs-btn:hover {
            color: #FFFFFF;
            background-color: #6AA5EC;
            box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
            transform: translateY(-5px);
        }







@media screen and (max-width: 720px) {

    .ceramic-head-tint {
        padding: 10rem 0 5rem 0;
    }

    .ceramic-card-wrapper {
        flex-direction: column;
        width: 85%;
    }

        .ceramic-card {
            width: 100%;
            height: auto;
            margin: .5rem auto;
            padding: .5rem 0;
        }

            .ceramic-card-line {
                margin: 0 auto .2rem auto;
            }

            .ceramic-card-description {
                padding: 0 0 .5rem 0;
            }

    .ceramic2-container {
        flex-direction: column;
        width: 100%;
    }

    .ceramic2-wrapper {
        max-width: 100%;
    }
        .ceramic2-header {
            display: none;
        }
        .ceramic2-logo__wrapper2 {
            margin: 0 auto;
            display: contents;
        }
            .ceramic2-header-small {
                width: 100%;
                font-size: clamp(1.4rem, 1.0183rem + 1.6285vw, 1.8rem);                
                font-weight: 400;
                margin: .1rem auto;
            }

            .op-logo2 {
                max-width: 95%;
                margin: 0 auto;
            }
                .op-logo2 > img {
                    width: 100%;
                }

        .op-logo {
            display: none;
        }

        .ceramic2-subheading {
            margin: 2px auto 10px auto;
            padding: 0;
            text-align: center;
            width: 95%;
        }

    .ceramic-faqs {
        padding: 0 0 1rem 0;
    }

        .faqs-wrapper {
            width: 90%;
        }

        .ceramic-faqs-subheading {
            width: 90%;
        }
}


@media screen and (max-width: 475px) {

    .ceramic-container {
        line-height: 3rem;
    }

    .ceramic-header {
        font-size: 2rem;
        width: 90%;
    }
}