.contact-page {
    width: 100%;  
}

.contact-head {
    width: 100%;
    background-size: cover;
    background-position: 0% 55%;
    background-repeat: no-repeat;
}

    .contact-tint {
        width: 100%;
        height: auto;
        padding: 15rem 0 10rem 0;
        background: rgba(0, 0, 0, .5);
    }

        .contact-header {
            margin: 0 auto;
            width: 95%;
            font-size: clamp(2.5rem, 2.2692rem + 0.9846vw, 3.5rem);
            font-weight: 450;
            letter-spacing: 2px;
        }

.contact-main {
    background: linear-gradient(to bottom right,  white 60%, black 60%);
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-around;
    padding: 1rem 0 0 0;
}

    .contact-info-header2 {
        display: none;
    }

    .contact-info-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 55%;
        color: black;
        flex-wrap: wrap;
    }
        .contact-info-header {
            width: 100%;
            color: #0081E4;
            font-weight: 400;
            text-align: left;
            margin: 0;
            line-height: 4rem;
            font-size: 2.5rem;
            > span {
                
                font-weight: 450;
                color: black;
            }
        }

        .contact-info-subheading {
            text-align: left;
            width: 95%;
            font-size: 1rem;
            line-height: 1.5rem;
            margin: 0;
            padding: .5rem 0 1rem 0;
        }

        .contact-logo-container {
            max-width: 50%;
        }
            .contact-logo-container > img {
                width: 100%;
            }

        .contact-icons-wrapper {
            width: 90%;
            height: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
        }
            .contact-icon-link {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                border: 2px solid #0081E4;
                border-radius: 5px;
                background-color: #0081E4;
                color: #FFFFFF; 
                width: fit-content;
                transition: all ease .3s;
                margin: 5px;
                flex-grow: 1;
            }
                .contact-icon-link:hover {
                    color: #0081E4;
                    background: #FFFFFF;
                    transition: all ease .3s;
                }

            .contact-icon {
                color: #FFFFFF;
                text-decoration: none;
                padding: 10px;
                font-size: 1rem;
                width: 100%;
                text-align: center;
                word-wrap: no-wrap;
            }
                .contact-icon:hover {
                    color: #0081E4;
                }

            .contact-icon > p {
                margin: 0;
            }
    .contact-wrapper {
        width: 40%;
        padding: 2rem 0;
    }

    @media screen and (max-width: 850px) {
        .contact-info-header {
            font-size: 2.2rem;
        }
    }


    @media screen and (max-width: 768px) {
        .contact-head {
            background-position: center;
        }

            .contact-tint {
                padding: 10rem 0 5rem 0;
            }

            .contact-main {
                flex-direction: column;
                justify-content: center;
            }
            
            .contact-info-header2 {
                display: block;
                text-align: center;
                color: #0081E4;
                font-weight: 400;
                text-align: center;
                margin: 0;
                line-height: 3rem;
                font-size: 2.5rem;
                > span {
                    
                    font-weight: 450;
                    color: black;
                }
            }

            .contact-info-wrapper {
                align-items: center;
                width: 90%;
                margin: 0 auto; 
            }

                .contact-info-header {
                    display: none;
                }

                .contact-info-subheading {
                    text-align: center;
                    width: 100%;
                    font-size: .9rem;
                }

                .contact-icon {
                    font-size: .9rem;
                }
            
            .contact-wrapper {
                width: 95%;
                margin: 0 auto;
                padding: 1rem 0;
            }
    }

    @media screen and (max-width: 480px){
        .contact-info-header2 {
            font-size: 2rem;
            line-height: 2.5rem;
        }
    }
