html, body {
    overflow-x: hidden;
}

.head-bg {
    width: 100%;
}

.header__container {
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.header__content {
    background: rgba(0, 0, 0, 0.65);
    padding: 15rem 0 10rem 0;
    align-items: center;
    color: white;
    display: flex;
    flex-direction: column;
    font-family: 'Raleway', sans-serif;
    height: 100%;
    justify-content: center;
    letter-spacing: .75rem;
    max-width: 100%;
}

.logo-container {
    max-width: 95%;
}
    .logo-container > img {
        width: 100%;
    }

.header__content > h2 {
    padding-top: 2rem;
    text-align: center;
    font-weight: 450;
    font-size: clamp(1.5rem, 1.4308rem + 0.2954vw, 1.8rem);
    line-height: 2rem;
}

.button__div {
    background-color: transparent;
    margin-top: 5rem;
    position: relative;
}


.lower__btn {
    background: black;
    border: 2px solid white;
    color: white;
    font-size: 1rem;
    font-weight: 400;
    padding: 15px 40px;
    transition: all ease 0.2s;
}

.lower__btn:hover {
    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
    transform: translateY(-5px);
}

/* Gallery Section Styling */


.gallery__section {
    width: 100%;
    font-family: 'Raleway', sans-serif;
}

    .gallery__main {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
    }

        .gallery_new {
            padding: 4rem 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-color: #6AA5EC;
        }

            .gallery-header {
                width: 95%;
                font-size: clamp(1.8rem, 1.5231rem + 1.1815vw, 3rem);
                font-weight: 450;
                text-align: center;
                letter-spacing: 2px;
                margin: 0 auto;
                line-height: 2.4rem;
            }
            .gallery-header > span {
                color: black;
                font-weight: 500;
            }

            .carousel-wrapper {
                width: 95%;
                margin: 0 auto;
            }

                .gallery__carousel {
                    width: 100%;
                }

                    .pic-card {
                        width: 95%;
                    }


                    .carousel__item {
                        width: 100%;
                        height: auto;
                        border-radius: 5px;
                    }

                    .react-multi-carousel-item {
                        opacity: .3;
                        transition: opacity .3s ease-in-out;
                    }

                    .react-multi-carousel-item.react-multi-carousel-item--active {
                        opacity: 1;
                    }

            .gallery__content {
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-top: .75rem;
            }

                .offers-wrapper {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: space-evenly;
                    flex-wrap: wrap;
                }

                    .gallery-offers {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: black;
                        padding: 2px 10px;
                        border-radius: 5px;
                        width: 18%;
                        margin: 0 0 5px 0;
                    }

                        .offers-caption {
                            margin-left: 2px;
                            text-align: center;
                            font-size: .9rem;
                        }

                .gallery__content > p {
                    color: white;
                    margin: 0 auto .5rem auto;
                    font-size: 1.1rem;
                    font-weight: 200;
                    line-height: 1.8rem;
                    width: 90%;
                }

                .services-btn{
                    background: transparent;
                    border: 2px solid white;
                    color: white;
                    font-size: 1rem;
                    font-weight: 400;
                    padding: 10px 20px;
                    border-radius: 5px;
                    transition: all ease 0.2s;
                }

                .services-btn:hover {
                    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
                    transform: translateY(-5px);
                }

.review__section {
    max-width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 0;    
}


.contact__info {
    margin: 0 auto;
    padding-top: 5rem;
}

.contact__info > address {
    border: 1px solid white;
    line-height: 1.7rem;
    padding: 10px 25px;
    text-align: center;
    width: 85%;
}

@media screen and (max-width: 1115px) {

    .gallery_new {
        flex-direction: column;
    }
    .gallery-header2 {
        display: block;
        font-family: 'Raleway', sans-serif;
        width: 95%;
        font-size: 2.5rem;
        font-weight: 400;
        letter-spacing: 2px;
        margin: 0 auto;
    }
        .gallery-header2 > span {
            color: black;
        }



   
}

@media screen and (max-width: 768px) {
    .header__content {
        padding: 10rem 0 5rem 0;
    }
    
    .gallery__header {
        font-size: 1.4rem;
        padding: 2rem 0;
    }
}

@media screen and (max-width: 630px) {

    .offers-wrapper {
        flex-wrap: wrap;
    }

    .gallery-offers {
        width: 40%;
    }

    .offers-caption {
        font-size: .8rem;
    }
}

@media screen and (max-width: 475px) {

    .gallery_new {
        padding: 2rem 0;
    }

    .gallery-header {
        font-size: 1.6rem;
    }

    .gallery__content > p {
        width: 95%;
        padding-top: 0;
        font-size: .9rem;
    }
    
}
