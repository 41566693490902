nav {
    align-items: center;
    background-color: transparent;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: .75rem 1rem;
    position: fixed;
    top: 0;
    transition: background-color 0.2s ease-in-out;
    width: 1920px;
    max-width: 100%;
    margin: 0 auto;
    max-width: 100%;
    z-index: 999;
}

.boxlogo {
    max-width: 80px;
    transition: all ease .3s;
}

.boxlogo:hover {
    background-color: #0081E4;
    transition: all ease .3s;
}

.hamburger {
    display: none;
}

nav > ul {
    align-items: center;
    display: flex;
    gap: 3rem;
    list-style: none;
    margin-right: 2rem;
    text-decoration: none;
}

.nav__item {
    background-image: linear-gradient(white 0 0);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 0% 2px;
    color: white;
    display: inline-block;
    font-family: 'Raleway', sans-serif;
    font-size: 1rem;
    padding-bottom:2px;
    text-align: center;
    text-decoration: none;
    
    transition:
        background-size 0.3s,
        background-position 0s 0.3s;
}

.nav__item:hover {
    background-position: 100% 100%;
    background-size: 100% 2px;
}

.icon__link {
    color: white;
    text-decoration: none;
    transition: all ease .3s;
}

.icon__link:hover {
    color: #0081E4;
    transition: all ease .3s;
}

.nav__button {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    font-family: 'Raleway', sans-serif;
    font-size: 1rem;
    overflow: hidden;
    padding: 5px 10px;
    position: relative;
    text-decoration: none;
    transition: .2s transform ease-in-out;
    will-change: transform;
    z-index: 0;
}

.nav__button:hover {
    border: 1px solid white;
    transform: scale(1.05);
    will-change: transform;
}

.nav__button::after {
    background: #0081E4;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform-origin: top left;
    transform: translate(-100%, 0) rotate(10deg);
    transition: .2s transform ease-out;
    width: 100%;
    will-change: transform;
    z-index: -1;
}

  .nav__button:hover::after {
    transform: translate(0, 0);
} 

.navbar.scrolled {
    background-color: black;
    box-shadow: 0 0 10px rgba(0);
}

@media screen and (max-width: 1000px) {
    
    .logo {
        margin-left: 0;
    }
    .hamburger {
        color: white;
        display: block;
        margin-right: 1.5rem;
    }

    nav > ul {
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, .9);
        display: none;
        flex-direction: column;
        left: 0;
        padding: 0;
        position: absolute;
        top: 60px;
        width: 100%;
        gap: 1.5rem;
    }
      nav > ul.is-open {
        align-items: center;
        display: flex;
        margin-top: 0;
        padding: 10px 0 20px 0;
    }
    .nav__item {
        gap: 0;
        margin: 0 auto;
        padding: 13px 0 13px 0;
        width: 100%;
    }
    .nav__button {
        background-color: transparent;
        border: 1px solid #0081E4;
        color: #FFFFFF;
        margin: 0 auto;
    }
}