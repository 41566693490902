.accordion-container {
    width: 100%;
    margin: 0 auto;
}

.accordion-question {
    text-align: left;
    margin: 5px 0;
    font-size: clamp(0.8rem, 0.4rem + 0.64vw, 1.2rem);
    font-weight: 400;
    line-height: 1.3;
}

.accordion-answer {
    text-align: left;
    margin-left: 5px;
    line-height: 1.3;
    font-size: clamp(0.8rem, 0.6rem + 0.32vw, 1rem);
}